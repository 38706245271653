import React from "react";
import Product from "../components/product";
import { OurProcess } from "../components/our-process";
import AboutOurProducts from "../components/partials/about-our-products";
import GoogleReviews from "../components/sections/google-reviews";
import Guarantee from "../components/sections/guarantee";
import ProductBundle from "../components/product-bundle";
import { useFeatureFlagVariantKey } from "posthog-js/react";

const CoversPage = () => {
  const variant = useFeatureFlagVariantKey("pdp-manual-quantity");
  console.log({ variant });

  return (
    <>
      {variant === "manual-quantity" && <Product category="covers" />}
      {variant !== "manual-quantity" && <ProductBundle category="covers" />}
      <OurProcess />
      <Guarantee />
      <AboutOurProducts />
      <GoogleReviews />
    </>
  );
};
export default CoversPage;
